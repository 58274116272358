import React from 'react'
import axios from 'axios'

export default class PricingPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: null,
    }
  }

  componentDidMount() {
    try {
      const WOW = require('wowjs')
      const wow = new WOW.WOW()
      wow.init()
    } catch (e) {
      console.error(e)
    }

    axios
      .get(`${process.env.API_URL}/umbraco/api/service/getresearch`)
      .then(result => {
        this.setState({
          data: result.data,
        })
      })
      .catch(error => {})
  }

  render() {
    const { data } = this.state
    let description = ''
    let photoUrl = ''

    if (data) {
      description = data.Description
      photoUrl = `${process.env.API_URL}${data.Photo}`
    }

    return (
      <div>
        <section className="section-top">
          <div className="container">
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="section-top-title">
                <h2>Preçário</h2>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-xs-12" />
          </div>
        </section>
        <section id="about" className="about_us section-padding">
          <div className="container">
            <div className="row text-center">
              <div className="col-sm-4 col-md-4">
                <div className="plan  first">
                  <div className="head head_color_one">
                    <h3>Day Care</h3>
                  </div>
                  <div className="price">
                    <h3>
                      <span className="symbol">$</span>19
                    </h3>
                    <h4>per month</h4>
                  </div>
                  <ul className="item-list">
                    <li>Meal</li>
                    <li>Snack</li>
                    <li>Games</li>
                    <li>Course</li>
                  </ul>
                </div>
              </div>
              <div className="col-sm-4 col-md-4">
                <div className="plan  first">
                  <div className="head head_color_one">
                    <h3>Day Care</h3>
                  </div>
                  <div className="price">
                    <h3>
                      <span className="symbol">$</span>19
                    </h3>
                    <h4>per month</h4>
                  </div>
                  <ul className="item-list">
                    <li>Meal</li>
                    <li>Snack</li>
                    <li>Games</li>
                    <li>Course</li>
                  </ul>
                </div>
              </div>
              <div className="col-sm-4 col-md-4">
                <div className="plan  first">
                  <div className="head head_color_one">
                    <h3>Day Care</h3>
                  </div>
                  <div className="price">
                    <h3>
                      <span className="symbol">$</span>19
                    </h3>
                    <h4>per month</h4>
                  </div>
                  <ul className="item-list">
                    <li>Meal</li>
                    <li>Snack</li>
                    <li>Games</li>
                    <li>Course</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}
